<template>
  <section
    class="relative w-full overflow-hidden"
    :style="background ? bgStyle : ''"
  >
    <slot></slot>
  </section>
</template>

<script>
export default {
  name: "SectionWrapper",
  props: {
    background: {
      type: String,
      default: ""
    }
  },
  computed: {
    bgStyle() {
      return `background-image: url('${this.background}'); background-size: cover; background-position: center center;`;
    }
  }
};
</script>
