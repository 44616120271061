<template>
  <div :class="`mx-auto ${mobilePadding ? 'max-xl:px-5' : ''}`">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    mobilePadding: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
